import gql from "graphql-tag";

export const UPDATE_PARTNER_BASIC_INFO = gql`
  mutation UpdatePartnerBasicInfo($partnerID: uuid, $_set: partners_set_input) {
    update_partners(where: { id: { _eq: $partnerID } }, _set: $_set) {
      affected_rows
    }
  }
`;

export const UPDATE_PARTNER_PAYMENT_INFO = gql`
  mutation UpdatePartnerPaymentInfo($partnerID: uuid, $_set: bankAccount_set_input) {
    update_bankAccount(where: { partnerID: { _eq: $partnerID } }, _set: $_set) {
      affected_rows
    }
  }
`;
export const INSERT_PARTNER_PAYMENT_INFO = gql`
  mutation InsertPartnerPaymentInfo($object: bankAccount_insert_input!) {
    insert_bankAccount_one(object: $object, on_conflict: { update_columns: [accountNumber, routingNumber, type], constraint: bankAccount_pkey }) {
      id
    }
  }
`;

export const UPDATE_PARTNER_ADDRESS_INFO = gql`
  mutation UpdatePartnerAddressInfo($id: uuid!, $_set: addresses_set_input) {
    update_addresses_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const INSERT_PARTNER_ADDRESS_INFO = gql`
  mutation InsertPartnerAddressInfo($_set: addresses_insert_input!, $partnerID: uuid) {
    insert_partners(objects: { id: $partnerID, address: { data: $_set } }, on_conflict: { constraint: driver_pkey, update_columns: addressID }) {
      affected_rows
    }
  }
`;

export const UPDATE_PARTNER_PII = gql`
  mutation UpdatePartnerPII($id: uuid!, $_set: partners_set_input) {
    update_partners_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const INSERT_PARTNER_DRIVERS_LICENSE = gql`
  mutation InsertPartnerDriversLicense($_set: [driversLicenses_insert_input!]!, $update_columns: [driversLicenses_update_column!]!) {
    insert_driversLicenses(objects: $_set, on_conflict: { constraint: driversLicenses_pkey, update_columns: $update_columns }) {
      affected_rows
    }
  }
`;

export const UPDATE_PARTNER_PROFILE = gql`
  mutation UpdatePartnerProfile($partnerID: uuid!, $profileSet: partners_set_input!) {
    update_partners_by_pk(pk_columns: { id: $partnerID }, _set: $profileSet) {
      addressID
      driversLicenses {
        id
      }
      bankAccounts {
        id
      }
    }
  }
`;

export const UPDATE_PARTNER_DRIVERS_LICENSE = gql`
  mutation UpdatePartnerDriversLicense($objects: [driversLicenses_insert_input!]!) {
    insert_driversLicenses(objects: $objects, on_conflict: { constraint: driversLicenses_pkey, update_columns: [country, expiration, number, state, status] }) {
      returning {
        id
      }
    }
  }
`;

// export const UPDATE_PARTNER_ADDRESS = gql`
//   mutation UpdatePartnerAddress($addressID: uuid!, $addressSet: addresses_set_input!) {
//     update_addresses_by_pk(pk_columns: { id: $addressID }, _set: $addressSet) {
//       id
//     }
//   }
// `;

export const UPDATE_PARTNER_ADDRESS = gql`
  mutation UpdatePartnerAddress($objects: [addresses_insert_input!]!) {
    insert_addresses(objects: $objects, on_conflict: { constraint: address_pkey, update_columns: [address1, address2, city, state, postalCode, country] }) {
      affected_rows
    }
  }
`;

export const ADD_PARTNER = gql`
  mutation AddPartner($object: partners_insert_input!) {
    insert_partners_one(object: $object) {
      id
    }
  }
`;

export const SAVE_PARTNER_NOTE = gql`
  mutation SavePartnerNote($partnerID: uuid!, $note: String) {
    update_clientLocationPartners(where: { partnerID: { _eq: $partnerID } }, _set: { notes: $note }) {
      returning {
        id
        notes
      }
    }
  }
`;

export const UPDATE_PARTNER_BANK_DETAILS = gql`
  mutation UpdatePartnerBankDetails($depositSet: [bankAccount_insert_input!]!) {
    insert_bankAccount(on_conflict: { constraint: bankAccount_pkey, update_columns: [accountNumber, routingNumber, type] }, objects: $depositSet) {
      affected_rows
    }
  }
`;

export const ADD_PARTNER_TEMPLATE = gql`
  mutation addFinanceTemplate($template: financeTemplates_insert_input!) {
    insert_financeTemplates_one(object: $template) {
      id
    }
  }
`;

export const ADD_PARTNER_SETTLEMENT_ITEM = gql`
  mutation addPartnerSettlementItem($settlement: settlementItems_insert_input!) {
    insert_settlementItems_one(object: $settlement) {
      id
    }
  }
`;

export const UPDATE_PARTNER_SETTLEMENT_ITEM = gql`
  mutation updatePartnerSettlementItem($id: uuid!, $addData: jsonb, $category: String, $coa: String, $desc: String, $name: String, $tax: taxSettings_enum, $txnType: transactionTypes_enum) {
    update_settlementItems_by_pk(
      pk_columns: { id: $id }
      _set: { additionalData: $addData, category: $category, chartOfAccounts: $coa, description: $desc, name: $name, taxSetting: $tax, transactionType: $txnType }
    ) {
      id
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation insertCommunicationsEmail($mailData: communicationsEmail_insert_input!) {
    insert_communicationsEmail_one(object: $mailData) {
      id
    }
  }
`;

export const SEND_TEXT = gql`
  mutation insertCommunicationsText($textData: communicationsText_insert_input!) {
    insert_communicationsText_one(object: $textData) {
      id
    }
  }
`;
